import { permission } from 'process';
import { SECTION, PERMISSION } from './permission.enum';

export const ROUTE_PERMISSIONS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [SECTION.DASHBOARD, PERMISSION.READ_DASHBOARD],

  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  // SYSTEM_TRACKING: [
  //   SECTION.SYSTEM_TRACKING,
  //   PERMISSION.READ_TRACKING
  // ],

  // -----------------------------------------------------
  // MAIN: ENTERPRISE
  // -----------------------------------------------------
  ENTERPRISE: [SECTION.ENTERPRISE, PERMISSION.READ_ENTERPRISE],
  // -----------------------------------------------------
  // MAIN: CONTRIBUTION
  // -----------------------------------------------------
  // -----------------Mission-----------------------------
  CONTRIBUTE: [
    PERMISSION.READ_CON_MISSION,
    PERMISSION.ALLOW_CREATE_CON_MISSION,
    PERMISSION.ALLOW_EDIT_CON_MISSION,
    PERMISSION.ALLOW_VERIFY_CON_MISSION,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION,
    PERMISSION.ALLOW_APPROVE_CON_MISSION,
    PERMISSION.ALLOW_RETURN_CON_MISSION,
    PERMISSION.ALLOW_REJECT_CON_MISSION,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION,
    PERMISSION.ALLOW_PRINT_CON_MISSION,

    PERMISSION.READ_CON_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_CON_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_CON_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_CON_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_CON_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_CON_MISSION_RESULT,
  ],

  COMPLAINT:[
    PERMISSION.READ_COMPLAIN_REQ,
    PERMISSION.ALLOW_CREATE_COMPLAIN_REQ,
    PERMISSION.ALLOW_EDIT_COMPLAIN_REQ,
    PERMISSION.ALLOW_RECEIVE_COMPLAIN_REQ,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_REQ,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_REQ,
    PERMISSION.ALLOW_RETURN_COMPLAIN_REQ,
    PERMISSION.ALLOW_REJECT_COMPLAIN_REQ,
    PERMISSION.ALLOW_PENDING_COMPLAIN_REQ,
    PERMISSION.ALLOW_END_COMPLAIN_REQ,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_REQ,
    PERMISSION.ALLOW_PRINT_COMPLAIN_REQ,

    PERMISSION.READ_COMPLAIN_MISSION,
    PERMISSION.ALLOW_CREATE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION,
    PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION,

    PERMISSION.READ_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION_RESULT,




  ],

  // -----------------------------------------------------
  // MAIN: NOTIFICATION
  // -----------------------------------------------------
  NOTIFICATION: [SECTION.NOTIFICATION, PERMISSION.READ_NOTIFICATION],

  WI_INSPECTION: [
    PERMISSION.READ_WI_REQ,
    PERMISSION.ALLOW_CREATE_WI_REQ,
    PERMISSION.ALLOW_EDIT_WI_REQ,
    PERMISSION.ALLOW_ACCEPT_WI_REQ,
    PERMISSION.ALLOW_REJECT_WI_REQ,
    PERMISSION.ALLOW_ALLOCATE_WI_REQ,
    PERMISSION.ALLOW_PRINT_WI_REQ,

    PERMISSION.READ_WI_RECORD,
    PERMISSION.ALLOW_EDIT_WI_RECORD,
    PERMISSION.ALLOW_ACCEPT_WI_RECORD,
    PERMISSION.ALLOW_REJECT_WI_RECORD,
    PERMISSION.ALLOW_ALLOCATE_WI_RECORD,
    PERMISSION.ALLOW_PRINT_WI_RECORD,

    PERMISSION.READ_WI_VICTIM,
    PERMISSION.ALLOW_CREATE_WI_VICTIM,
    PERMISSION.ALLOW_EDIT_WI_VICTIM,
    PERMISSION.ALLOW_ACCEPT_WI_VICTIM,
    PERMISSION.ALLOW_RETURN_WI_VICTIM,
    PERMISSION.ALLOW_REJECT_WI_VICTIM,
    PERMISSION.ALLOW_ALLOCATE_WI_VICTIM,
    PERMISSION.ALLOW_PRINT_WI_VICTIM,

    PERMISSION.READ_WI_MISSION,
    PERMISSION.ALLOW_CREATE_WI_MISSION,
    PERMISSION.ALLOW_EDIT_WI_MISSION,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION,
    PERMISSION.ALLOW_RETURN_WI_MISSION,
    PERMISSION.ALLOW_REJECT_WI_MISSION,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION,
    PERMISSION.ALLOW_PRINT_WI_MISSION,

    PERMISSION.READ_WI_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_WI_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_WI_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_WI_MISSION_RESULT,

    PERMISSION.READ_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_CREATE_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_EDIT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_RETURN_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_REJECT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_PRINT_WI_MISSION_FOLLOW_UP,
  ],

  // -----------------------------------------------------
  // MAIN: PENALTY MISSION
  // -----------------------------------------------------
  PENALTY_RES: [
    PERMISSION.READ_PENALTY_MISSION,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION,

    PERMISSION.READ_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION_RESULT,

    PERMISSION.READ_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION_FOLLOW_UP,
  ],

  // -----------------------------------------------------
  // MAIN: USERS
  // -----------------------------------------------------
  NSSF_USER: [
    SECTION.NSSF_USER,
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],

  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    SECTION.SETTING,
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
};

export const EXTENDED_ROUTE_PMS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],

  // -----------------------------------------------------
  // MAIN: ENNTERPRISE
  // -----------------------------------------------------
  ENTERPRISE: [PERMISSION.READ_ENTERPRISE],

  // -----------------------------------------------------
  // MAIN: ENTERPRISE_REQUEST
  // -----------------------------------------------------
  ENTERPRISE_REQUEST: [
    PERMISSION.READ_ENT_REQ,
    PERMISSION.ALLOW_REGISTER_ENT_REQ,
    PERMISSION.ALLOW_APPROVE_ENT_REQ,
    PERMISSION.ALLOW_REJECT_ENT_REQ,
    PERMISSION.ALLOW_RETURN_ENT_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: ENTERPRISE_CAMDX
  // -----------------------------------------------------
  ENTERPRISE_CAMDX: [
    PERMISSION.READ_CAMDX_ENT_REQ,
    PERMISSION.ALLOW_APPROVE_CAMDX_ENT_REQ,
    PERMISSION.ALLOW_RETURN_CAMDX_ENT_REQ,
    PERMISSION.ALLOW_REJECT_CAMDX_ENT_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: ENTERPRISE_LIST
  // -----------------------------------------------------
  ENTERPRISE_LIST: [PERMISSION.READ_ENT],
  // -----------------------------------------------------
  // MAIN: CONTRIBUTION
  // -----------------------------------------------------
  // -----------------Mission-----------------------------
  MISSION_CONTRIBUTE: [
    PERMISSION.READ_CON_MISSION,
    PERMISSION.ALLOW_CREATE_CON_MISSION,
    PERMISSION.ALLOW_EDIT_CON_MISSION,
    PERMISSION.ALLOW_VERIFY_CON_MISSION,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION,
    PERMISSION.ALLOW_RETURN_CON_MISSION,
    PERMISSION.ALLOW_REJECT_CON_MISSION,
    PERMISSION.ALLOW_PRINT_CON_MISSION,
  ],
  // -----------------Mission Reuslt-----------------------------
  MISSION_RESULT_CONTRIBUTE: [
    PERMISSION.READ_CON_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_CON_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_CON_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_CON_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_CON_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_CON_MISSION_RESULT,
  ],

  // -----------------------------------------------------
  // MAIN: PLAN
  // -----------------------------------------------------
  PLAN: [
    PERMISSION.READ_PLAN,
    PERMISSION.ALLOW_CREATE_PLAN,
    PERMISSION.ALLOW_APPROVE_PLAN,
    PERMISSION.ALLOW_RETURN_PLAN,
    PERMISSION.ALLOW_REJECT_PLAN,
  ],

  // -----------------------------------------------------
  // MAIN: MISSION
  // -----------------------------------------------------
  MISSION: [
    PERMISSION.READ_MISSION,
    PERMISSION.ALLOW_CREATE_MISSION,
    PERMISSION.ALLOW_APPROVE_MISSION,
    PERMISSION.ALLOW_RETURN_MISSION,
    PERMISSION.ALLOW_REJECT_MISSION,
  ],

  // -----------------------------------------------------
  // MAIN: MISSION_RESULT
  // -----------------------------------------------------
  MISSION_RESULT: [
    PERMISSION.READ_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_MISSION_RESULT,
  ],

  // -----------------------------------------------------
  // MAIN: READ_FOLLOW_UP
  // -----------------------------------------------------
  FOLLOW_UP: [
    PERMISSION.READ_FOLLOW_UP,
    PERMISSION.ALLOW_CREATE_FOLLOW_UP,
    PERMISSION.ALLOW_APPROVE_FOLLOW_UP,
    PERMISSION.ALLOW_RETURN_FOLLOW_UP,
    PERMISSION.ALLOW_REJECT_FOLLOW_UP,
  ],

  // -----------------------------------------------------
  // MAIN: COMPLAINT_REQ
  // -----------------------------------------------------
  COMPLAINT_REQ: [
    PERMISSION.READ_COMPLAIN_REQ,
    PERMISSION.ALLOW_CREATE_COMPLAIN_REQ,
    PERMISSION.ALLOW_EDIT_COMPLAIN_REQ,
    PERMISSION.ALLOW_RECEIVE_COMPLAIN_REQ,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_REQ,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_REQ,
    PERMISSION.ALLOW_RETURN_COMPLAIN_REQ,
    PERMISSION.ALLOW_REJECT_COMPLAIN_REQ,
    PERMISSION.ALLOW_PENDING_COMPLAIN_REQ,
    PERMISSION.ALLOW_END_COMPLAIN_REQ,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_REQ,
    PERMISSION.ALLOW_PRINT_COMPLAIN_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: COMPLAINT_MISSION
  // -----------------------------------------------------
  COMPLAINT_MISSION:[
    PERMISSION.READ_COMPLAIN_MISSION,
    PERMISSION.ALLOW_CREATE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION,
    PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION,
    PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION,
  ],

  // -----------------------------------------------------
  // MAIN: COMPLAINT_MISSION_REPORT
  // -----------------------------------------------------
  COMPLAINT_MISSION_REPORT: [
    PERMISSION.READ_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION_RESULT,
  ],
  


  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  // SYSTEM_TRACKING: [
  //   PERMISSION.READ_TRACKING
  // ],

  // -----------------------------------------------------
  // MAIN: WI REQUEST
  // -----------------------------------------------------
  WI_REQUEST: [
    PERMISSION.READ_WI_REQ,
    PERMISSION.ALLOW_CREATE_WI_REQ,
    PERMISSION.ALLOW_EDIT_WI_REQ,
    PERMISSION.ALLOW_ACCEPT_WI_REQ,
    PERMISSION.ALLOW_REJECT_WI_REQ,
    PERMISSION.ALLOW_ALLOCATE_WI_REQ,
    PERMISSION.ALLOW_PRINT_WI_REQ,
  ],

  // -----------------------------------------------------
  // MAIN: WI RECORD
  // -----------------------------------------------------
  WI_RECORD: [
    PERMISSION.READ_WI_RECORD,
    PERMISSION.ALLOW_EDIT_WI_RECORD,
    PERMISSION.ALLOW_ACCEPT_WI_RECORD,
    PERMISSION.ALLOW_REJECT_WI_RECORD,
    PERMISSION.ALLOW_ALLOCATE_WI_RECORD,
    PERMISSION.ALLOW_PRINT_WI_RECORD,
  ],

  // -----------------------------------------------------
  // MAIN: WI WICTIM
  // -----------------------------------------------------
  WI_VICTIM: [
    PERMISSION.READ_WI_VICTIM,
    PERMISSION.ALLOW_CREATE_WI_VICTIM,
    PERMISSION.ALLOW_EDIT_WI_VICTIM,
    PERMISSION.ALLOW_ACCEPT_WI_VICTIM,
    PERMISSION.ALLOW_RETURN_WI_VICTIM,
    PERMISSION.ALLOW_REJECT_WI_VICTIM,
    PERMISSION.ALLOW_ALLOCATE_WI_VICTIM,
    PERMISSION.ALLOW_PRINT_WI_VICTIM,
  ],

  // -----------------------------------------------------
  // MAIN: WI MISSION
  // -----------------------------------------------------
  WI_MISSION: [
    PERMISSION.READ_WI_MISSION,
    PERMISSION.ALLOW_CREATE_WI_MISSION,
    PERMISSION.ALLOW_EDIT_WI_MISSION,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION,
    PERMISSION.ALLOW_RETURN_WI_MISSION,
    PERMISSION.ALLOW_REJECT_WI_MISSION,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION,
    PERMISSION.ALLOW_PRINT_WI_MISSION,
  ],

  // -----------------------------------------------------
  // MAIN: WI MISSION RESULT
  // -----------------------------------------------------
  WI_MISSION_RESULT: [
    PERMISSION.READ_WI_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_WI_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_WI_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_WI_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_WI_MISSION_RESULT,
  ],

  // -----------------------------------------------------
  // MAIN: WI MISSION FOLLOW UP
  // -----------------------------------------------------
  WI_MISSION_FOLLOW_UP: [
    PERMISSION.READ_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_CREATE_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_EDIT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ACCEPT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_RETURN_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_REJECT_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ALLOCATE_WI_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_PRINT_WI_MISSION_FOLLOW_UP,
  ],
  
  // -----------------------------------------------------
  // MAIN: PENALTY MISSION
  // -----------------------------------------------------
  PENALTY_MISSION: [
    PERMISSION.READ_PENALTY_MISSION,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION,
  ],

  // -----------------------------------------------------
  // MAIN: PENALTY MISSION RESULTS
  // -----------------------------------------------------
  PENALTY_MISSION_RESULT: [
    PERMISSION.READ_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION_RESULT,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION_RESULT,
  ],

  // -----------------------------------------------------
  // MAIN: PENALTY MISSION FOLLOW UP
  // -----------------------------------------------------
  PENALTY_MISSION_FOLLOW_UP: [
    PERMISSION.READ_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_CREATE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_EDIT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_VERIFY_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_APPROVE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_RETURN_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_REJECT_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION_FOLLOW_UP,
    PERMISSION.ALLOW_PRINT_PENALTY_MISSION_FOLLOW_UP,
  ],

  // -----------------------------------------------------
  // MAIN: USERS
  // -----------------------------------------------------
  NSSF_USER: [
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],

  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
};

export const ROUTE_PMS_JSON = [
  {
    name: SECTION.DASHBOARD,
    key: 'DASHBOARD',
    icon: 'dashboard',
    children: [
      {
        name: SECTION.DASHBOARD_WORK,
        path: '/dashboard',
        pms_keys: [PERMISSION.READ_DASHBOARD],
      },
    ],
  },
  {
    name: SECTION.ENTERPRISE,
    key: 'ENTERPRISE',
    icon: 'account_balance',
    children: [
      {
        name: SECTION.ENTERPRISE_REQUEST,
        path: '/enterprise-request',
        pms_keys: EXTENDED_ROUTE_PMS.ENTERPRISE_REQUEST,
      },
      {
        name: SECTION.ENTERPRISE_CAMDX,
        path: '/enterprise/enterprise-camdx',
        pms_keys: EXTENDED_ROUTE_PMS.ENTERPRISE_CAMDX,
      },
      {
        name: SECTION.ENTERPRISE_LIST,
        path: '/enterprise/enterprise-list',
        pms_keys: EXTENDED_ROUTE_PMS.ENTERPRISE_LIST,
      },
      {
        name: SECTION.PLAN,
        path: '/enterprise/plan',
        pms_keys: EXTENDED_ROUTE_PMS.PLAN,
      },
      {
        name: SECTION.MISSION,
        path: '/enterprise/mission',
        pms_keys: EXTENDED_ROUTE_PMS.MISSION,
      },
      {
        name: SECTION.MISSION_RESULT,
        path: '/enterprise/mission-result',
        pms_keys: EXTENDED_ROUTE_PMS.MISSION_RESULT,
      },
      {
        name: SECTION.FOLLOW_UP,
        path: '/enterprise/follow-up',
        pms_keys: EXTENDED_ROUTE_PMS.FOLLOW_UP,
      },
    ],
  },
  {
    name: SECTION.NOTIFICATION,
    key: 'CONTRIBUTE',
    icon: 'local_atm',
    children: [
      {
        name: SECTION.MISSION_CONTRIBUTE,
        path: '/notifi-contribution-payment/mission-contribution-payment',
        pms_keys: EXTENDED_ROUTE_PMS.MISSION_CONTRIBUTE,
      },
      {
        name: SECTION.MISSION_RESULT,
        path: '/notifi-contribution-payment/mission-report',
        pms_keys: EXTENDED_ROUTE_PMS.MISSION_RESULT_CONTRIBUTE,
      },
    ],
  },
  {
    name: SECTION.TRANSITIONAL_PENALTY,
    key: 'PENALTY_RES',  
    icon: 'corporate_fare',
    children: [
      // {
      //   name: SECTION.NON_PAYMENT,
      //   path: '/transitional-penalties/non-payment/list',
      //   pms_keys: [
      //     // PERMISSION.READ_INSPECT_REQ,
      //     // PERMISSION.ALLOW_CREATE_INSPECT_REQ,
      //     // PERMISSION.ALLOW_EDIT_INSPECT_REC,
      //     // PERMISSION.ALLOW_DELETE_INSPECT_REQ,
      //   ],
      // },
      // {
      //   name: SECTION.PENALTY,
      //   path: '/transitional-penalties/penalty',
      //   pms_keys: [
      //     // PERMISSION.READ_INSPECT_REQ,
      //     // PERMISSION.ALLOW_CREATE_INSPECT_REQ,
      //     // PERMISSION.ALLOW_EDIT_INSPECT_REC,
      //     // PERMISSION.ALLOW_DELETE_INSPECT_REQ,
      //   ],
      // },
      {
        name: SECTION.PENALTY_MISSION,
        path: '/transitional-penalties/transitional-penalty-mission',
        pms_keys: [
          PERMISSION.READ_PENALTY_MISSION,
          PERMISSION.ALLOW_CREATE_PENALTY_MISSION,
          PERMISSION.ALLOW_EDIT_PENALTY_MISSION,
          PERMISSION.ALLOW_VERIFY_PENALTY_MISSION,
          PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION,
          PERMISSION.ALLOW_APPROVE_PENALTY_MISSION,
          PERMISSION.ALLOW_RETURN_PENALTY_MISSION,
          PERMISSION.ALLOW_REJECT_PENALTY_MISSION,
          PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION,
          PERMISSION.ALLOW_PRINT_PENALTY_MISSION,
        ],
      },
      {
        name: SECTION.PENALTY_MISSION_REPORT,
        path: '/transitional-penalties/mission-report',
        pms_keys: [
          PERMISSION.READ_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_CREATE_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_EDIT_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_VERIFY_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_ACCEPT_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_APPROVE_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_RETURN_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_REJECT_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_ALLOCATE_PENALTY_MISSION_RESULT,
          PERMISSION.ALLOW_PRINT_PENALTY_MISSION_RESULT,
        ],
      },
      {
        name: SECTION.FOLLOW_UP_PENALTY,
        path: '/transitional-penalties/follow-up-penalty',
        pms_keys: EXTENDED_ROUTE_PMS.PENALTY_MISSION_FOLLOW_UP,
      },
    ],
  },
  {
    name: SECTION.COMPLAINT,
        icon: 'gavel',
        root: 'complaint',
        key:'COMPLAINT',
        children: [
          {
            name: SECTION.COMPLAINT_LIST,
            path: '/complaint/complaint-list',
            pms_keys:[
              PERMISSION.READ_COMPLAIN_REQ,
              PERMISSION.ALLOW_CREATE_COMPLAIN_REQ,
              PERMISSION.ALLOW_EDIT_COMPLAIN_REQ,
              PERMISSION.ALLOW_RECEIVE_COMPLAIN_REQ,
              PERMISSION.ALLOW_VERIFY_COMPLAIN_REQ,
              PERMISSION.ALLOW_ACCEPT_COMPLAIN_REQ,
              PERMISSION.ALLOW_RETURN_COMPLAIN_REQ,
              PERMISSION.ALLOW_REJECT_COMPLAIN_REQ,
              PERMISSION.ALLOW_PENDING_COMPLAIN_REQ,
              PERMISSION.ALLOW_END_COMPLAIN_REQ,
              PERMISSION.ALLOW_ALLOCATE_COMPLAIN_REQ,
              PERMISSION.ALLOW_PRINT_COMPLAIN_REQ,
            ],
          },
          {
            name: SECTION.COMPLAINT_MISSION,
            path: '/complaint/complaint-mission',
            pms_keys:[
              PERMISSION.READ_COMPLAIN_MISSION,
              PERMISSION.ALLOW_CREATE_COMPLAIN_MISSION,
              PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION,
              PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION,
              PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION,
              PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION,
              PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION,
              PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION,
              PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION,
              PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION,
            ],
          },
          {
            name: SECTION.COMPLAINT_MISSION_REPORT,
            path: '/complaint/complaint-mission-report/list',
            pms_keys:[
              PERMISSION.READ_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_EDIT_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_VERIFY_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_ACCEPT_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_APPROVE_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_RETURN_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_REJECT_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_ALLOCATE_COMPLAIN_MISSION_RESULT,
              PERMISSION.ALLOW_PRINT_COMPLAIN_MISSION_RESULT,
            ],
          },
        ],
  },
  {
    name: SECTION.WI_INSPECTION_HEAD,
    key: 'WI_INSPECTION',
    icon: 'corporate_fare',
    children: [
      {
        name: SECTION.WI_REQUEST,
        path: '/wi-inspection/request',
        pms_keys: EXTENDED_ROUTE_PMS.WI_REQUEST,
      },
      {
        name: SECTION.WI_VICTIM,
        path: '/wi-inspection/wi-member-list',
        pms_keys: EXTENDED_ROUTE_PMS.WI_VICTIM,
      },
      {
        name: SECTION.WI_MISSION,
        path: '/wi-inspection/mission',
        pms_keys: EXTENDED_ROUTE_PMS.WI_MISSION,
      },
      {
        name: SECTION.WI_MISSION_RESULT,
        path: '/wi-inspection/mission-result',
        pms_keys: EXTENDED_ROUTE_PMS.WI_MISSION_RESULT,
      },
    ],
  },

  {
    name: SECTION.NSSF_USER,
    key: 'NSSF_USER',
    icon: 'manage_accounts',
    children: [
      {
        name: SECTION.NSSF_USER,
        path: '/user-admin',
        pms_keys: EXTENDED_ROUTE_PMS.NSSF_USER,
      },
    ],
  },
  {
    name: SECTION.SETTING,
    key: 'SETTING',
    icon: 'settings',
    children: [
      {
        name: SECTION.SETTING,
        path: '/organization',
        pms_keys: EXTENDED_ROUTE_PMS.SETTING,
      },
    ],
  },
];
