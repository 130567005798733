import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { LANG } from 'src/app/models/core/enums/lang.enum';
import { LocalStorageEnum } from 'src/app/models/core/enums/local-storage.enum';
import { HISTORY_TYPE } from 'src/app/models/enums/history-type.enum';
import { STATUS } from 'src/app/models/enums/status.enum';
import { User } from 'src/app/models/user';
import { LocalStorageService } from 'src/app/services/core/local-storage.service';
import { DepartmentService } from 'src/app/services/department.service';
import { UserAdminService } from 'src/app/services/user-admin.service';

@Component({
  selector: 'app-assign-dialog',
  templateUrl: './assign-dialog.component.html',
  styleUrls: ['./assign-dialog.component.scss'],
})
export class AssignDialogComponent implements OnInit, OnDestroy {
  status: string = '';
  lang: LANG;
  user: User | any;
  license: any = null;
  formDataGroup: UntypedFormGroup;
  departments: Array<any> = [];
  users: Array<User> = [];
  documents: Array<any> = [];
  // selectedDepartment: any = null;
  // selectedUser: any = null;
  con_id: string;
  type: string;

  readonly LANG = LANG;

  private _destroyed = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private departmentService: DepartmentService,
    private userService: UserAdminService,
    private localStorageService: LocalStorageService,
    private dialogRef: MatDialogRef<AssignDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.con_id = data?.ref_id;
    this.type = data?.type;

    if (this.type === HISTORY_TYPE.TRANSFER_TO_DOCTOR) {
      this.data.title = 'menu.claim_report.assign_to_doctor';
    } else if (this.type === HISTORY_TYPE.TRANSFER_TO_CALCULATOR) {
      this.data.title = 'menu.claim_report.assign_to_calculator';
    }
    // this.documents = data?.license?.documents ? data.license.documents : null;
    // this.license = data?.license;
    this.formDataGroup = this.formBuilder.group({
      to_department: ['', Validators.required],
      to_user: ['', data?.required ? Validators.required : []],
      description: ['', Validators.required],
    });

    this.translate.onLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang as LANG;
      });
    this.lang = this.translate.currentLang as LANG;
    this.user = JSON.parse(
      this.localStorageService.decryptSpecialCharacter(LocalStorageEnum.user) ||
        ''
    );
  }

  ngOnInit(): void {
    this.getAllDepartment();
    this.getDepartmentByCurrentUser(this.user);
  }

  onSubmit() {
    const value = this.formDataGroup.value;
    let department = value.to_department;
    let body = {
      ref_id: this.con_id,
      description: value.description,
      to_department: department ? department : this.user.department,
      to_user: value.to_user,
      type: this.type,
    };

    if (this.status == '') {
      if (department && value.to_user) {
        this.status = STATUS.ASSIGN;
      } else {
        this.status = STATUS.ALLOCATE;
      }
    }

    this.dialogRef.close({
      status: this.status,
      body: body,
    });
  }

  /**
   *
   * @param user
   */
  getDepartmentByCurrentUser(user: any) {
    this.getUserByDepartment(user.department._id);
  }

  /**
   *
   * @param departmentID
   */
  getUserByDepartment(departmentID: string) {
    this.resetSelectedUser();
    this.userService
      .getUserByDepartment(departmentID, 1, 100)
      .subscribe((res) => {
        this.users = res.data;
      });
  }

  getAllDepartment() {
    this.departmentService.getAllDepartment(1, 100).subscribe((res) => {
      if (res) {
        this.departments = res.data?.departments;
      }
    });
  }

  resetSelectedUser() {
    this.formDataGroup.controls['to_user'].setValue(null);
  }

  /**
   *
   * @param user
   * @returns
   */
  getUserName(user: any) {
    if (!user) return '';

    if (user.first_name_kh || user.last_name_kh) {
      if (this.lang != 'en')
        return user.last_name_kh + ' ' + user.first_name_kh;
      else return user.last_name_en + ' ' + user.first_name_en;
    } else if (user.first_name_en || user.last_name_en) {
      return user.last_name_en + ' ' + user.first_name_en;
    } else {
      return user.last_name + ' ' + user.first_name;
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
