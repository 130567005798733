import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CallbackComponent } from './components/core/callback/callback.component';
import { NavigationComponent } from './components/core/navigation/navigation.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        data: {},
        loadChildren: () => import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'risk-inpection',
        loadChildren: () => import('./components/risk-inspection/risk-inspection.module').then(m => m.RiskInspectionModule)
      },
      {
        path: 'user-admin',
        data: {},
        loadChildren: () => import('./components/user-admin/user-admin.module').then(m => m.UserAdminModule)
      },
      {
        path: 'risk-report',
        data: {},
        loadChildren: () => import('./components/risk-report/risk-report.module').then(m => m.RiskReportModule)
      },
      {
        path: 'permission',
        data: { },
        loadChildren: () => import('./components/permission/permission.module').then(m => m.PermissionModule)
      },
      {
        path: 'accident-type',
        data: { },
        loadChildren: () => import('./components/accident/components/accident.module').then(m => m.AccidentModule)
      },
      {
        path: 'accident-degree',
        data: { },
        loadChildren: () => import('./components/accident/accident-degree/components/accident-degree.module').then(m => m.AccidentDegreeModule)
      },
      {
        path: 'accident-category',
        data: { },
        loadChildren: () => import('./components/accident/accident-category/components/accident-category.module').then(m => m.AccidentCategoryModule)
      },
      {
        path: 'health-status',
        data: { },
        loadChildren: () => import('./components/setting-sections/health-status/components/health-status.module').then(m => m.HealthStatusModule)
      },
      {
        path: 'injury-part',
        data: { },
        loadChildren: () => import('./components/setting-sections/injury-part/components/injury-part.module').then(m => m.InjuryPartModule)
      },
      {
        path: 'injury-type',
        data: { },
        loadChildren: () => import('./components/setting-sections/injury-type/components/injury-type.module').then(m => m.InjuryTypeModule)
      },
      {
        path : 'remind_payment',
        data :{},
        loadChildren: () => import('./components/contribution_payment/contribute-payment.module').then(m => m.ContributePaymentModule)
      },
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./components/core/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'auth/callback',
    component: CallbackComponent
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
