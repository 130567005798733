import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileExtension } from 'src/app/models/enums/fileview-type';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit, OnChanges {
 
  @Input() text: string = "";
  @Input() enableDownload: boolean = true;
  @Input() enableView: boolean = true;
  @Input() enableDelete: boolean = true;
  @Input() svgIcon = "";
  // I ADD ON BEFORE CAUSE delete is an keyword
  @Output() onDownload = new EventEmitter<void>();
  @Output() onView = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();

  @Input() rowClass = "";
  @Input() contentType: any;
  readonly FileExtension = FileExtension;
  isViewFileType: boolean = false;
  // @Input() comparing: boolean = false;
  // @Input() deleted: boolean | null = null;
  // @Input() old: boolean | null = null;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contentType?.currentValue){
      if(this.contentType == FileExtension.IMAGE_JPG || this.contentType == FileExtension.IMAGE_PNG || this.contentType == FileExtension.PDF){
        this.isViewFileType = true;
      } else {
        this.isViewFileType = false;
      }
    }
  }

  emitAction(output: EventEmitter<any>): void {
    output.emit();
  }

}
