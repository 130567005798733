import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullFeedbackAlertComponent } from './full-feedback-alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from '../pipe/pipe.module';
import {TextFieldModule} from '@angular/cdk/text-field';
import { SelectModule } from '../select/select.module';



@NgModule({
  declarations: [
    FullFeedbackAlertComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    PipeModule,
    TextFieldModule,
		SelectModule
  ]
})
export class FullFeedbackAlertModule { }
