import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { LocalStorageEnum } from 'src/app/models/core/enums/local-storage.enum';
import { PERMISSION } from 'src/app/models/core/enums/permission.enum';
import { FullFeedbackAlertType } from 'src/app/models/enums/full-feedback-alert-type';
import { LocalStorageService } from 'src/app/services/core/local-storage.service';

export interface FullFeedbackAlertData {
  type: FullFeedbackAlertType,
  title: string,
  messages: string[],
  notify?: boolean,
  confirm?: boolean,
  positive?: string,
  positiveIcon?: string,
  negative?: string,
  negativeIcon?: string,
  requiredReason?: boolean,
  reasonLabel?: string
  template?: TemplateRef<any>,
  form?: AbstractControl,
  date?: any,
  [key: string]: any
}

@Component({
  selector: 'app-full-feedback-alert',
  templateUrl: './full-feedback-alert.component.html',
  styleUrls: ['./full-feedback-alert.component.scss']
})
export class FullFeedbackAlertComponent implements OnInit {

  descriprion!: UntypedFormControl;
  conOrderNum!: UntypedFormControl;
  form?: AbstractControl;

  readonly TYPE = FullFeedbackAlertType; // FOR NO MODIFY TO THE ENUM

  constructor(
    private localStorage: LocalStorageService,
    private dialogRef: MatDialogRef<FullFeedbackAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FullFeedbackAlertData
  ) {
    if (this.data.requiredReason) {
      this.descriprion = new UntypedFormControl(null, Validators.required);
    }

    if (this.data.resetContribute) {
      this.conOrderNum = new UntypedFormControl(null, Validators.required);
    }

    // if (this.data.form) {
    //   this.form = this.data.form as UntypedFormArray;

    //   const permissions = this.localStorage.getDecryption(LocalStorageEnum.permissions)!.split(",");
    //   if (this.data.isDependentType || permissions.includes(PERMISSION.ALLOW_EDIT_WITHOUT_ATTACHMENT)) {
    //     this.form.clearValidators();
    //     this.form.updateValueAndValidity();
    //   }
    // }
  }

  ngOnInit(): void {
    this.initialize();
  }

  onSubmit() {
    if (this.descriprion?.invalid) {
      this.descriprion?.markAllAsTouched()
    } else {
      this.dialogRef.close(this.descriprion?.value);
    }
  }

  onSubmitJsonResetContribute() {
    if (this.conOrderNum?.invalid && this.data.order_nums.length > 1) {
      this.conOrderNum?.markAllAsTouched()
    } else {
      let index = this.conOrderNum?.value;
      this.dialogRef.close(index ? index : 1);
    }
  }

  async initialize(): Promise<void> {
    this.dialogRef.addPanelClass(['animate__animated', 'animate__slideInUp', 'animate__faster']);
    await lastValueFrom(this.dialogRef.beforeClosed());
    this.dialogRef.removePanelClass(['animate__animated', 'animate__slideInUp', 'animate__faster']);
    this.dialogRef.addPanelClass(['animate__animated', 'animate__fadeOutDown', 'animate__faster']);
  }
}
