import { TableSmoothScrollDirective } from './../table-smooth-scroll.directive';
import { IntlSmoothScrollDirective } from './../intl-smooth-scroll.directive';
import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { SmoothScrollDirective } from '../smooth-scroll.directive';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    SmoothScrollDirective,
    IntlSmoothScrollDirective,
    TableSmoothScrollDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [SmoothScrollDirective, IntlSmoothScrollDirective, TableSmoothScrollDirective]
})
export class SmoothScrollModule { }
