// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url : 'https://nssf-ben-api.myplgs.com/admin/api/v1',
  api_url: 'https://ins-nssf-api.myplgs.com/admin/api/v1',
  app_url: 'http://localhost:50005',

  // api_url: 'http://localhost:30005/admin/api/v1',
  // api_url: "http://172.17.0.88:30008/admin/api/v1",
  // api_url: 'http://172.17.0.122:30005/admin/api/v1'
  // api_url: 'http://172.17.0.88:30005/admin/api/v1' // IP B PAHNA
  // api_url: 'http://172.17.1.81:30005/admin/api/v1' // IP SOVANN PAHNA

  // LIMIT_PRINT_CARD: 20,
  GOOGLE_MAP_API_KEY: 'AIzaSyCVpvjQh5InGMcJ65-N4DFsYJsWBUHnDek',
  DEFAULT_USER_IMAGE: "/assets/images/default_profile.png",
  VERSION: "0.0.1",


  // NSSF_REDIRECT_URI: 'https://nssf-ins-admin.myplgs.com/auth/callback',
  // NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh/connect',
  // NSSF_ADMIN_AUTH_CLIENT_ID: '2ADA5F1E-357D-401B-92A3-F817C567FEDF',
  // NSSF_ADMIN_AUTH_CLIENT_SECRET: 'CBEAF593-951D-424B-8098-A7C8C66BFDFD',
  // NSSF_CLIENT_CODE_VERIFIER: 'od38lK15WykzQ19B-jbwtKFDa9HF8oiY4u1H87elLbU'

  /** OAUTH2 */
  NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh',
  NSSF_REDIRECT_URI: 'http://localhost:50005/auth/callback',
  NSSF_LOGOUT_REDIRECT_URI: 'http://localhost:50005/login',
  NSSF_ADMIN_AUTH_CLIENT_ID: '2ADA5F1E-357D-401B-92A3-F817C567FEDF',
  IS_ENABLE_OAUTH: false,
  IS_SYSTEM_TESTING: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
