export enum RESPONSE_STATUS {
  SUCCESS = 1,
  ERROR_CLIENT = 0,
  ERROR_SERVER = -1,
  UNKNOWN = 2,
  DUPLICATED = -3,
  UNUSUAL = -4,

  NOT_FOUND = 401,
  INTERNAL_SERVER_ERROR = 500,
}
