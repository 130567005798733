import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import SmoothScrollbar from 'smooth-scrollbar';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { LANG } from 'src/app/models/core/enums/lang.enum';
import { LocalStorageEnum } from 'src/app/models/core/enums/local-storage.enum';
import { MenuItem } from 'src/app/models/core/menu-item';
import { HeaderTitle } from 'src/app/models/header-title';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/core/auth.service';
import { LocalStorageService } from 'src/app/services/core/local-storage.service';
import { RequestService } from 'src/app/services/core/request.service';
import { SingleSignOnService } from 'src/app/services/core/single-sign-on.service';
import { UtilService } from 'src/app/services/core/util.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeAnimation],
})
export class NavigationComponent implements OnInit, OnDestroy {
  isTablet!: boolean;
  isHandset!: boolean;
  NSSF_AUTH_URL: string = environment.NSSF_AUTH_URL;
  IS_ENABLE_OAUTH: boolean = environment.IS_ENABLE_OAUTH;
  NSSF_ADMIN_AUTH_CLIENT_ID: string = environment.NSSF_ADMIN_AUTH_CLIENT_ID;
  // IS_SYSTEM_TESTING: boolean = environment.IS_SYSTEM_TESTING;
  version: string = environment.VERSION;

  isContentScrolled!: boolean;
  scrollToTop: boolean = false;

  displayMenuItems: MenuItem[] = [];

  isScrolled!: boolean;
  isMenuIcon: boolean = false;
  menuState: 'open' | 'close' = 'close';
  user!: User;
  lang: string = '';
  permissions: string[] = [];
  title: HeaderTitle | null = null;
  showActionButton: boolean = false;
  readonly LANG = LANG;

  private _destroyed = new Subject<void>();

  constructor(
    private singleSignOnService: SingleSignOnService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    public util: UtilService,
    private authService: AuthService,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private navigationService: NavigationService,
    private _cd: ChangeDetectorRef,
    private _router: Router
  ) {
    this.lang = this.translate.currentLang as LANG;

    let value: any = this.localStorageService.decryptSpecialCharacter(LocalStorageEnum.user);
    this.user = JSON.parse(value ? value : '');

    this.util.showActionButton.pipe(takeUntil(this._destroyed)).subscribe((res) => {
      this.showActionButton = !!res;
    });

    this.permissions = localStorageService.getArray(LocalStorageEnum.permissions);

    // Auto logout
    if (this.IS_ENABLE_OAUTH && !this.singleSignOnService.isLoggedIn) {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  private _updateActive(root: string) {
    const active = this.displayMenuItems.find((menu) => menu.root === root);
    if (Boolean(active)) {
      if (!active!.expanded) {
        active!.expanded = true;
        this._cd.detectChanges();
      }
    }
  }

  ngOnInit(): void {
    this.getMenuItems();
    this._updateActive(this._router.url.slice(1).split('/').shift()!);
    this._router.events.pipe(takeUntil(this._destroyed)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        const root = ev.url.slice(1).split('/').shift()!;
        this._updateActive(root);
      }
    });

    this.util.headerTitle.subscribe((title) => {
      this.title = title;
    });

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isHandset = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.breakpointObserver
      .observe(Breakpoints.TabletPortrait)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isTablet = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.translate.onLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang as LANG;
      });
  }

  private traverseMenuItems(menuItems: MenuItem[]) {
    return menuItems.filter((item) => {
      if (item.children) {
        item.children = this.traverseMenuItems(item.children);
      }

      // if (!item.roles || !this.user?.role?.code) {
      //   return true;
      // }

      // return item.roles.includes(this.user.role.code);
      return true; //show all menus
    });
  }

  getMenuItems() {
    const menuItems = this.navigationService.getMenuItems(this.permissions);
    this.displayMenuItems = this.traverseMenuItems(menuItems);
  }

  onToggleMenu(): void {
    this.isMenuIcon = !this.isMenuIcon;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  onCloseMenu(): void {
    this.isMenuIcon = true;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  onMenuScroll(e: ScrollStatus): void {
    let scrolled = e.offset.y > 0;
    if (this.isScrolled != scrolled) {
      this.isScrolled = scrolled;
      this._cd.detectChanges();
    }
  }

  onClickMenu() {
    if (!this.isMenuIcon) {
      this.isMenuIcon = this.isHandset || this.isTablet;
    }
  }

  onLogout() {
    if (this.IS_ENABLE_OAUTH && this.singleSignOnService.isLoggedIn) {
      this.singleSignOnService.onLogout();
    } else {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  onScroll(status: ScrollStatus): void {
    let scrolled = status.offset.y > 200;
    if (this.isContentScrolled != scrolled) {
      this.isContentScrolled = scrolled;
      this._cd.detectChanges();
    }

    this.util.navigationScroll.emit(status);
  }

  onScrollLoaded(scroll: SmoothScrollbar): void {
    this.util.navigationScrollRef = scroll;
  }

  moveTop() {
    this.util.navigationScrollRef.scrollTo(0, 0, 350);
    this.util.navigationScrollRef.setMomentum(0, 1);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
