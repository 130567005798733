export enum FileType {
    HEALTHCARE = "HEALTHCARE",
    WORKINJURY = "WORKINJURY"
}

export enum FileExtension {
    PDF = "application/pdf",
    IMAGE_JPG = "image/jpeg",
    IMAGE_PNG = "image/png",
    ZIPPED_FILE = ".zip",
    COMPRESSED_FILE = "application/x-zip-compressed"
}