/**
 * @NOTE: Search by key: 'MAIN' , 'SUB'
 */
export enum PERMISSION {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  READ_DASHBOARD = 'READ_DASHBOARD',

  // -----------------------------------------------------
  // MAIN: ENTERPRISE
  // -----------------------------------------------------
  READ_ENTERPRISE = 'READ_ENTERPRISE',

  // -----------------------------------------------------
  // MAIN: READ_ENT_REQ
  // -----------------------------------------------------
  READ_ENT_REQ = 'READ_ENT_REQ',
  ALLOW_REGISTER_ENT_REQ = 'ALLOW_REGISTER_ENT_REQ',
  ALLOW_APPROVE_ENT_REQ = 'ALLOW_APPROVE_ENT_REQ',
  ALLOW_RETURN_ENT_REQ = 'ALLOW_RETURN_ENT_REQ',
  ALLOW_REJECT_ENT_REQ = 'ALLOW_REJECT_ENT_REQ',
  // -----------------------------------------------------
  // MAIN: READ_CAMDX_ENT_REQ
  // -----------------------------------------------------
  READ_CAMDX_ENT_REQ = 'READ_CAMDX_ENT_REQ',
  ALLOW_APPROVE_CAMDX_ENT_REQ = 'ALLOW_APPROVE_CAMDX_ENT_REQ',
  ALLOW_RETURN_CAMDX_ENT_REQ = 'ALLOW_RETURN_CAMDX_ENT_REQ',
  ALLOW_REJECT_CAMDX_ENT_REQ = 'ALLOW_REJECT_CAMDX_ENT_REQ',

  // -----------------------------------------------------
  // MAIN: READ_ENT
  // -----------------------------------------------------
  READ_ENT = 'READ_ENT',

  // -----------------------------------------------------
  // MAIN: ENTERPRISE_PROPOSAL
  // -----------------------------------------------------
  READ_PLAN = 'READ_PLAN',
  ALLOW_CREATE_PLAN = 'ALLOW_CREATE_PLAN',
  ALLOW_APPROVE_PLAN = 'ALLOW_APPROVE_PLAN',
  ALLOW_RETURN_PLAN = 'ALLOW_RETURN_PLAN',
  ALLOW_REJECT_PLAN = 'ALLOW_REJECT_PLAN',

  // -----------------------------------------------------
  // MAIN: ENTERPRISE_PROPOSAL
  // -----------------------------------------------------
  READ_MISSION = 'READ_MISSION',
  ALLOW_CREATE_MISSION = 'ALLOW_CREATE_MISSION',
  ALLOW_APPROVE_MISSION = 'ALLOW_APPROVE_MISSION',
  ALLOW_RETURN_MISSION = 'ALLOW_RETURN_MISSION',
  ALLOW_REJECT_MISSION = 'ALLOW_REJECT_MISSION',
    // MAIN: Contribute
  // -----------------------------------------------------
  // MAIN: MISSION
  // -----------------------------------------------------
  READ_CON_MISSION = 'READ_CON_MISSION',
  ALLOW_CREATE_CON_MISSION = 'ALLOW_CREATE_CON_MISSION',
  ALLOW_EDIT_CON_MISSION = 'ALLOW_EDIT_CON_MISSION',
  ALLOW_VERIFY_CON_MISSION = 'ALLOW_VERIFY_CON_MISSION',
  ALLOW_ACCEPT_CON_MISSION = 'ALLOW_ACCEPT_CON_MISSION',
  ALLOW_APPROVE_CON_MISSION = 'ALLOW_APPROVE_CON_MISSION',
  ALLOW_RETURN_CON_MISSION = 'ALLOW_RETURN_CON_MISSION',
  ALLOW_REJECT_CON_MISSION = 'ALLOW_REJECT_CON_MISSION',
  ALLOW_ALLOCATE_CON_MISSION = 'ALLOW_ALLOCATE_CON_MISSION',
  ALLOW_PRINT_CON_MISSION = 'ALLOW_PRINT_CON_MISSION',

  // -----------------------------------------------------
  // MAIN: MISSION_RESULT
  // -----------------------------------------------------
  READ_CON_MISSION_RESULT = 'READ_CON_MISSION_RESULT',
  ALLOW_CREATE_CON_MISSION_RESULT = 'ALLOW_CREATE_CON_MISSION_RESULT',
  ALLOW_EDIT_CON_MISSION_RESULT = 'ALLOW_EDIT_CON_MISSION_RESULT',
  ALLOW_VERIFY_CON_MISSION_RESULT = 'ALLOW_VERIFY_CON_MISSION_RESULT',
  ALLOW_ACCEPT_CON_MISSION_RESULT = 'ALLOW_ACCEPT_CON_MISSION_RESULT',
  ALLOW_APPROVE_CON_MISSION_RESULT = 'ALLOW_APPROVE_CON_MISSION_RESULT',
  ALLOW_RETURN_CON_MISSION_RESULT = 'ALLOW_RETURN_CON_MISSION_RESULT',
  ALLOW_REJECT_CON_MISSION_RESULT = 'ALLOW_REJECT_CON_MISSION_RESULT',
  ALLOW_ALLOCATE_CON_MISSION_RESULT = 'ALLOW_ALLOCATE_CON_MISSION_RESULT',
  ALLOW_PRINT_CON_MISSION_RESULT = 'ALLOW_PRINT_CON_MISSION_RESULT',

  // -----------------------------------------------------
  // MAIN: READ_MISSION_RESULT
  // -----------------------------------------------------
  READ_MISSION_RESULT = 'READ_MISSION_RESULT',
  ALLOW_CREATE_MISSION_RESULT = 'ALLOW_CREATE_MISSION_RESULT',
  ALLOW_APPROVE_MISSION_RESULT = 'ALLOW_APPROVE_MISSION_RESULT',
  ALLOW_RETURN_MISSION_RESULT = 'ALLOW_RETURN_MISSION_RESULT',
  ALLOW_REJECT_MISSION_RESULT = 'ALLOW_REJECT_MISSION_RESULT',

  // -----------------------------------------------------
  // MAIN: READ_FOLLOW_UP
  // -----------------------------------------------------
  READ_FOLLOW_UP = 'READ_FOLLOW_UP',
  ALLOW_CREATE_FOLLOW_UP = 'ALLOW_CREATE_FOLLOW_UP',
  ALLOW_APPROVE_FOLLOW_UP = 'ALLOW_APPROVE_FOLLOW_UP',
  ALLOW_RETURN_FOLLOW_UP = 'ALLOW_RETURN_FOLLOW_UP',
  ALLOW_REJECT_FOLLOW_UP = 'ALLOW_REJECT_FOLLOW_UP',


  // -----------------------------------------------------
  // MAIN: COMPLAINT_REQ
  // -----------------------------------------------------
  READ_COMPLAIN_REQ = 'READ_COMPLAIN_REQ',
  ALLOW_CREATE_COMPLAIN_REQ = 'ALLOW_CREATE_COMPLAIN_REQ',
  ALLOW_EDIT_COMPLAIN_REQ = 'ALLOW_EDIT_COMPLAIN_REQ',
  ALLOW_RECEIVE_COMPLAIN_REQ = 'ALLOW_RECEIVE_COMPLAIN_REQ',
  ALLOW_VERIFY_COMPLAIN_REQ = 'ALLOW_VERIFY_COMPLAIN_REQ',
  ALLOW_ACCEPT_COMPLAIN_REQ = 'ALLOW_ACCEPT_COMPLAIN_REQ',
  ALLOW_RETURN_COMPLAIN_REQ = 'ALLOW_RETURN_COMPLAIN_REQ',
  ALLOW_REJECT_COMPLAIN_REQ = 'ALLOW_REJECT_COMPLAIN_REQ',
  ALLOW_PENDING_COMPLAIN_REQ = 'ALLOW_PENDING_COMPLAIN_REQ',
  ALLOW_END_COMPLAIN_REQ = 'ALLOW_END_COMPLAIN_REQ',
  ALLOW_ALLOCATE_COMPLAIN_REQ = 'ALLOW_ALLOCATE_COMPLAIN_REQ',
  ALLOW_PRINT_COMPLAIN_REQ = 'ALLOW_PRINT_COMPLAIN_REQ',

  // -----------------------------------------------------
  // MAIN: COMPLAINT_MISSION
  // -----------------------------------------------------
  READ_COMPLAIN_MISSION = 'READ_COMPLAIN_MISSION',
  ALLOW_CREATE_COMPLAIN_MISSION = 'ALLOW_CREATE_COMPLAIN_MISSION',
  ALLOW_EDIT_COMPLAIN_MISSION = 'ALLOW_EDIT_COMPLAIN_MISSION',
  ALLOW_VERIFY_COMPLAIN_MISSION = 'ALLOW_VERIFY_COMPLAIN_MISSION',
  ALLOW_ACCEPT_COMPLAIN_MISSION = 'ALLOW_ACCEPT_COMPLAIN_MISSION',
  ALLOW_APPROVE_COMPLAIN_MISSION = 'ALLOW_APPROVE_COMPLAIN_MISSION',
  ALLOW_RETURN_COMPLAIN_MISSION = 'ALLOW_RETURN_COMPLAIN_MISSION',
  ALLOW_REJECT_COMPLAIN_MISSION = 'ALLOW_REJECT_COMPLAIN_MISSION',
  ALLOW_ALLOCATE_COMPLAIN_MISSION = 'ALLOW_ALLOCATE_COMPLAIN_MISSION',
  ALLOW_PRINT_COMPLAIN_MISSION = 'ALLOW_PRINT_COMPLAIN_MISSION',

  // -----------------------------------------------------
  // MAIN: COMPLAINT_MISSION_REPORT
  // -----------------------------------------------------
  READ_COMPLAIN_MISSION_RESULT = 'READ_COMPLAIN_MISSION_RESULT',
  ALLOW_CREATE_COMPLAIN_MISSION_RESULT = 'ALLOW_CREATE_COMPLAIN_MISSION_RESULT',
  ALLOW_EDIT_COMPLAIN_MISSION_RESULT = 'ALLOW_EDIT_COMPLAIN_MISSION_RESULT',
  ALLOW_VERIFY_COMPLAIN_MISSION_RESULT = 'ALLOW_VERIFY_COMPLAIN_MISSION_RESULT',
  ALLOW_ACCEPT_COMPLAIN_MISSION_RESULT = 'ALLOW_ACCEPT_COMPLAIN_MISSION_RESULT',
  ALLOW_APPROVE_COMPLAIN_MISSION_RESULT = 'ALLOW_APPROVE_COMPLAIN_MISSION_RESULT',
  ALLOW_RETURN_COMPLAIN_MISSION_RESULT = 'ALLOW_RETURN_COMPLAIN_MISSION_RESULT',
  ALLOW_REJECT_COMPLAIN_MISSION_RESULT = 'ALLOW_REJECT_COMPLAIN_MISSION_RESULT',
  ALLOW_ALLOCATE_COMPLAIN_MISSION_RESULT = 'ALLOW_ALLOCATE_COMPLAIN_MISSION_RESULT',
  ALLOW_PRINT_COMPLAIN_MISSION_RESULT = 'ALLOW_PRINT_COMPLAIN_MISSION_RESULT',



  // -----------------------------------------------------
  // MAIN: NOTIFICATION
  // -----------------------------------------------------

  READ_NOTIFICATION = 'READ_NOTIFICATION',

  // -----------------------------------------------------
  // MAIN: NOTIFICATION_REQUEST
  // -----------------------------------------------------

  READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST',

  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  READ_TRACKING = 'READ_TRACKING',

  // -----------------------------------------------------
  // MAIN: WI REQUEST
  // -----------------------------------------------------
  READ_WI_REQ = 'READ_WI_REQ',
  ALLOW_CREATE_WI_REQ = 'ALLOW_CREATE_WI_REQ',
  ALLOW_EDIT_WI_REQ = 'ALLOW_EDIT_WI_REQ',
  ALLOW_ACCEPT_WI_REQ = 'ALLOW_ACCEPT_WI_REQ',
  ALLOW_REJECT_WI_REQ = 'ALLOW_REJECT_WI_REQ',
  ALLOW_ALLOCATE_WI_REQ = 'ALLOW_ALLOCATE_WI_REQ',
  ALLOW_PRINT_WI_REQ = 'ALLOW_PRINT_WI_REQ',

  // -----------------------------------------------------
  // MAIN: WI RECORD
  // -----------------------------------------------------
  READ_WI_RECORD = 'READ_WI_RECORD',
  ALLOW_EDIT_WI_RECORD = 'ALLOW_EDIT_WI_RECORD',
  ALLOW_ACCEPT_WI_RECORD = 'ALLOW_ACCEPT_WI_RECORD',
  ALLOW_REJECT_WI_RECORD = 'ALLOW_REJECT_WI_RECORD',
  ALLOW_ALLOCATE_WI_RECORD = 'ALLOW_ALLOCATE_WI_RECORD',
  ALLOW_PRINT_WI_RECORD = 'ALLOW_PRINT_WI_RECORD',

  // -----------------------------------------------------
  // MAIN: WI VICTIM
  // -----------------------------------------------------
  READ_WI_VICTIM = 'READ_WI_VICTIM',
  ALLOW_CREATE_WI_VICTIM = 'ALLOW_CREATE_WI_VICTIM',
  ALLOW_EDIT_WI_VICTIM = 'ALLOW_EDIT_WI_VICTIM',
  ALLOW_ACCEPT_WI_VICTIM = 'ALLOW_ACCEPT_WI_VICTIM',
  ALLOW_RETURN_WI_VICTIM = 'ALLOW_RETURN_WI_VICTIM',
  ALLOW_REJECT_WI_VICTIM = 'ALLOW_REJECT_WI_VICTIM',
  ALLOW_ALLOCATE_WI_VICTIM = 'ALLOW_ALLOCATE_WI_VICTIM',
  ALLOW_PRINT_WI_VICTIM = 'ALLOW_PRINT_WI_VICTIM',

  // -----------------------------------------------------
  // MAIN: WI MISSION
  // -----------------------------------------------------
  READ_WI_MISSION = 'READ_WI_MISSION',
  ALLOW_CREATE_WI_MISSION = 'ALLOW_CREATE_WI_MISSION',
  ALLOW_EDIT_WI_MISSION = 'ALLOW_EDIT_WI_MISSION',
  ALLOW_ACCEPT_WI_MISSION = 'ALLOW_ACCEPT_WI_MISSION',
  ALLOW_RETURN_WI_MISSION = 'ALLOW_RETURN_WI_MISSION',
  ALLOW_REJECT_WI_MISSION = 'ALLOW_REJECT_WI_MISSION',
  ALLOW_ALLOCATE_WI_MISSION = 'ALLOW_ALLOCATE_WI_MISSION',
  ALLOW_PRINT_WI_MISSION = 'ALLOW_PRINT_WI_MISSION',

  // -----------------------------------------------------
  // MAIN: WI MISSION RESULT
  // -----------------------------------------------------
  READ_WI_MISSION_RESULT = 'READ_WI_MISSION_RESULT',
  ALLOW_CREATE_WI_MISSION_RESULT = 'ALLOW_CREATE_WI_MISSION_RESULT',
  ALLOW_EDIT_WI_MISSION_RESULT = 'ALLOW_EDIT_WI_MISSION_RESULT',
  ALLOW_ACCEPT_WI_MISSION_RESULT = 'ALLOW_ACCEPT_WI_MISSION_RESULT',
  ALLOW_RETURN_WI_MISSION_RESULT = 'ALLOW_RETURN_WI_MISSION_RESULT',
  ALLOW_REJECT_WI_MISSION_RESULT = 'ALLOW_REJECT_WI_MISSION_RESULT',
  ALLOW_ALLOCATE_WI_MISSION_RESULT = 'ALLOW_ALLOCATE_WI_MISSION_RESULT',
  ALLOW_PRINT_WI_MISSION_RESULT = 'ALLOW_PRINT_WI_MISSION_RESULT',

  // -----------------------------------------------------
  // MAIN: WI MISSION RESULT
  // -----------------------------------------------------
  READ_WI_MISSION_FOLLOW_UP = 'READ_WI_MISSION_FOLLOW_UP',
  ALLOW_CREATE_WI_MISSION_FOLLOW_UP = 'ALLOW_CREATE_WI_MISSION_FOLLOW_UP',
  ALLOW_EDIT_WI_MISSION_FOLLOW_UP = 'ALLOW_EDIT_WI_MISSION_FOLLOW_UP',
  ALLOW_ACCEPT_WI_MISSION_FOLLOW_UP = 'ALLOW_ACCEPT_WI_MISSION_FOLLOW_UP',
  ALLOW_RETURN_WI_MISSION_FOLLOW_UP = 'ALLOW_RETURN_WI_MISSION_FOLLOW_UP',
  ALLOW_REJECT_WI_MISSION_FOLLOW_UP = 'ALLOW_REJECT_WI_MISSION_FOLLOW_UP',
  ALLOW_ALLOCATE_WI_MISSION_FOLLOW_UP = 'ALLOW_ALLOCATE_WI_MISSION_FOLLOW_UP',
  ALLOW_PRINT_WI_MISSION_FOLLOW_UP = 'ALLOW_PRINT_WI_MISSION_FOLLOW_UP',

  // -----------------------------------------------------
  // MAIN: PENALTY MISSON
  // -----------------------------------------------------
  READ_PENALTY_MISSION = 'READ_PENALTY_MISSION',
  ALLOW_CREATE_PENALTY_MISSION = 'ALLOW_CREATE_PENALTY_MISSION',
  ALLOW_EDIT_PENALTY_MISSION = 'ALLOW_EDIT_PENALTY_MISSION',
  ALLOW_VERIFY_PENALTY_MISSION = 'ALLOW_VERIFY_PENALTY_MISSION',
  ALLOW_ACCEPT_PENALTY_MISSION = 'ALLOW_ACCEPT_PENALTY_MISSION',
  ALLOW_APPROVE_PENALTY_MISSION = 'ALLOW_APPROVE_PENALTY_MISSION',
  ALLOW_RETURN_PENALTY_MISSION = 'ALLOW_RETURN_PENALTY_MISSION',
  ALLOW_REJECT_PENALTY_MISSION = 'ALLOW_REJECT_PENALTY_MISSION',
  ALLOW_ALLOCATE_PENALTY_MISSION = 'ALLOW_ALLOCATE_PENALTY_MISSION',
  ALLOW_PRINT_PENALTY_MISSION = 'ALLOW_PRINT_PENALTY_MISSION',

  // -----------------------------------------------------
  // MAIN: PENALTY MISSON RESULTS
  // -----------------------------------------------------
  READ_PENALTY_MISSION_RESULT = 'READ_PENALTY_MISSION_RESULT',
  ALLOW_CREATE_PENALTY_MISSION_RESULT = 'ALLOW_CREATE_PENALTY_MISSION_RESULT',
  ALLOW_EDIT_PENALTY_MISSION_RESULT = 'ALLOW_EDIT_PENALTY_MISSION_RESULT',
  ALLOW_VERIFY_PENALTY_MISSION_RESULT = 'ALLOW_VERIFY_PENALTY_MISSION_RESULT',
  ALLOW_ACCEPT_PENALTY_MISSION_RESULT = 'ALLOW_ACCEPT_PENALTY_MISSION_RESULT',
  ALLOW_APPROVE_PENALTY_MISSION_RESULT = 'ALLOW_APPROVE_PENALTY_MISSION_RESULT',
  ALLOW_RETURN_PENALTY_MISSION_RESULT = 'ALLOW_RETURN_PENALTY_MISSION_RESULT',
  ALLOW_REJECT_PENALTY_MISSION_RESULT = 'ALLOW_REJECT_PENALTY_MISSION_RESULT',
  ALLOW_ALLOCATE_PENALTY_MISSION_RESULT = 'ALLOW_ALLOCATE_PENALTY_MISSION_RESULT',
  ALLOW_PRINT_PENALTY_MISSION_RESULT = 'ALLOW_PRINT_PENALTY_MISSION_RESULT',

  // -----------------------------------------------------
  // MAIN: PENALTY MISSON RESULTS
  // -----------------------------------------------------
  READ_PENALTY_MISSION_FOLLOW_UP = 'READ_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_CREATE_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_CREATE_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_EDIT_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_EDIT_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_VERIFY_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_VERIFY_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_ACCEPT_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_ACCEPT_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_APPROVE_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_APPROVE_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_RETURN_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_RETURN_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_REJECT_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_REJECT_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_ALLOCATE_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_ALLOCATE_PENALTY_MISSION_FOLLOW_UP',
  ALLOW_PRINT_PENALTY_MISSION_FOLLOW_UP = 'ALLOW_PRINT_PENALTY_MISSION_FOLLOW_UP',

  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  READ_NSSF_USER = 'READ_NSSF_USER',
  ALLOW_CREATE_NSSF_USER = 'ALLOW_CREATE_NSSF_USER',
  ALLOW_EDIT_NSSF_USER = 'ALLOW_EDIT_NSSF_USER',
  ALLOW_DISABLE_NSSF_USER = 'ALLOW_DISABLE_NSSF_USER',

  // -----------------------------------------------------
  // MAIN: SETTINGS
  // -----------------------------------------------------
  READ_SETTING = 'READ_SETTING',
  ALLOW_CREATE_SETTING = 'ALLOW_CREATE_SETTING',
  ALLOW_EDIT_SETTING = 'ALLOW_EDIT_SETTING',
  ALLOW_DISABLE_SETTING = 'ALLOW_DISABLE_SETTING',

  // -----------------------------------------------------
  // MAIN: REPORTS
  // -----------------------------------------------------
  // READ_ALL_REPORT = "READ_ALL_REPORT"
}

/**
 * @NOTE: Search by key: 'MAIN'
 */
export enum SECTION {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD = 'menu.dashboard',
  DASHBOARD_WORK = 'dashboard.dashboard_work',

  // -----------------------------------------------------
  // MAIN: ENTERPRISE
  // -----------------------------------------------------
  ENTERPRISE = 'menu.enterprise.title',
  ENTERPRISE_REQUEST = 'menu.enterprise.request',
  ENTERPRISE_CAMDX = 'menu.enterprise.enterprise_camdx',
  ENTERPRISE_LIST = 'menu.enterprise.list',
  PLAN = 'menu.enterprise.plan',
  MISSION = 'menu.enterprise.mission',
  MISSION_RESULT = 'menu.enterprise.mission_result',
  FOLLOW_UP = 'menu.enterprise.follow_up',

  // -----------------------------------------------------
  // MAIN: NOTIFICATION
  // -----------------------------------------------------
  NOTIFICATION = 'menu.enterprise.notification_pay',
  NOTIFICATION_LETTER = 'menu.notification.notification_letter',
  CONTRIBUTEPAYMENT = 'menu.notification.contribute_payment',
  MISSION_CONTRIBUTE = 'menu.enterprise.mission_payment',
  // -----------------------------------------------------
  // MAIN: COMPLAINT
  // -----------------------------------------------------
  COMPLAINT = 'menu.complaint.title',
  COMPLAINT_LIST = 'menu.complaint.complaint_list',
  COMPLAINT_MISSION = 'menu.complaint.complaint_mission',
  COMPLAINT_MISSION_REPORT = 'menu.complaint.complaint_mission_report',
  // NOTIFICATION_LETTER = 'menu.notification.notification_letter',

  // -----------------------------------------------------
  // MAIN: TRANSITIONAL PENALTY
  // -----------------------------------------------------
  TRANSITIONAL_PENALTY = 'menu.transitional_penalties.title',
  PENALTY = 'menu.transitional_penalties.penalty',
  NON_PAYMENT = 'menu.transitional_penalties.non_payment',
  PENALTY_MISSION = 'menu.transitional_penalties.penalty_mission',
  PENALTY_MISSION_REPORT = 'menu.transitional_penalties.mission_report',
  FOLLOW_UP_PENALTY = 'menu.transitional_penalties.follow_up_penalty',
  // -----------------------------------------------------
  // MAIN: SYSTEM TRACKING
  // -----------------------------------------------------
  SYSTEM_TRACKING = 'menu.tracking_system',

  // -----------------------------------------------------
  // MAIN: INSPECTION REQUEST
  // -----------------------------------------------------
  WI_INSPECTION_HEAD = 'menu.inspection.title',
  WI_REQUEST = 'menu.inspection.request',
  WI_RECORD = 'menu.inspection.request',
  WI_VICTIM = 'menu.inspection.wi_member_list.title',
  WI_MISSION = 'menu.inspection.wi_mission',
  WI_MISSION_RESULT = 'menu.inspection.mission_result.title',

  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  NSSF_USER = 'menu.user_admin',

  // -----------------------------------------------------
  // MAIN: SETTINGS
  // -----------------------------------------------------
  SETTING = 'menu.setting',
  SETTING_DEPARTMENT = 'menu.department',
  SETTING_ROLE = 'menu.role',

  // -----------------------------------------------------
  // MAIN: REPORTS
  // -----------------------------------------------------
  ALL_REPORT = 'menu.report',
}
