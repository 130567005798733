import { Pipe, PipeTransform } from '@angular/core';
import { PERMISSION } from 'src/app/models/core/enums/permission.enum';

@Pipe({
  name: 'roleTranslate',
})
export class RoleTranslatePipe implements PipeTransform {
  main_permission_keys = [
    'READ',
    'CREATE',
    'REGISTER',
    'EDIT',
    'DELETE',
    'REMOVE',
    'SUBMIT',
    'VERIFY',
    'APPROVE',
    'ACCEPT',
    'RETURN',
    'REJECT',
    'DISABLE',
    'TOPUP',
    'ALLOCATE',
  ];

  exceptions = [
    PERMISSION.ALLOW_MERGE_SEPARATE_WI_VICTIM,
    "ALLOW_DOWNLOAD_WORK_ACTIVITY_REQ",
  ];

  transform(value: string): string {
    let result = this.main_permission_keys.find((key: string) => value.includes(key));
    let exception = this.exceptions.find((key: string) => value.includes(key));

    return `pms_child_code.${exception || result || value}`;
  }
}
