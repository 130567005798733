import { CdkDrag, DragRef } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Point } from 'chart.js';
import { lastValueFrom, timer } from 'rxjs';

export interface FileViewerDialogData {
  url: string;
  name: string;
  type: string;
}

interface DOMRect extends DOMRectReadOnly {
  height: number;
  width: number;
  x: number;
  y: number;
}

@Component({
  selector: 'app-file-viewer-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
  url: SafeResourceUrl;
  filename: string = '';
  isImage: boolean = false;

  zoom = 'page-width';
  isVisible: boolean = false;
  onTop = false;

  static zIndex = 0;

  dragging = false;

  public globalWrapper!: HTMLElement;

  @ViewChild("dragRef", { static: true }) dragRef!: CdkDrag;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FileViewerDialogData,
    private domSanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<FileViewerDialogComponent>,
    private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef
  ) {
    this.dialogRef.addPanelClass(['resize-dialog', "file-view"]);
    this.dialogRef.updateSize("866px", "90vh");
    this.dialogRef.updatePosition({
      top: "0",
      left: "0"
    });
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    this.filename = this.data?.name || '';
    // this.isImage = !!(this.data?.type.includes('image') || this.data?.type.includes('octet-stream'));

    if (this.dialog.openDialogs.filter(dialog => dialog.componentInstance instanceof FileViewerDialogComponent).length === 0) {
      FileViewerDialogComponent.zIndex = 0;
    }
  }

  ngOnInit(): void {
    timer(0).subscribe(() => {
      let rootEl = this.dragRef.getRootElement(),
      x = window.innerWidth / 2 - rootEl.clientWidth / 2,
      y = window.innerHeight / 2 - rootEl.clientHeight / 2;
      rootEl.style.transform = `translate(${x}px, ${y}px)`;
      // root
    });
    this.globalWrapper = this.getGlobalWrapperEl();
    this.handleActiveView();
    this.visible();
  }

  async visible(): Promise<void> {
    await lastValueFrom(timer(100));
    this.isVisible = true;
    // this.cd.markForCheck();
  }

  dragPositionHandler(userPointerPosition: Point, dragRef: DragRef<CdkDrag>, dimensions: DOMRect, pickupPositionInElement: Point): Point {
    let dragEl = dragRef.data.element.nativeElement,
    actionsEl = dragEl.querySelector(".actions")!;
    return {
      x: Math.max(
        Math.min(userPointerPosition.x - pickupPositionInElement.x, window.innerWidth - dimensions.width),
        actionsEl.clientWidth - dimensions.width + actionsEl.clientWidth / 2
      ),
      y: Math.min(Math.max((userPointerPosition.y - pickupPositionInElement.y), 0), window.innerHeight - dragEl.offsetHeight)
    };
  }

  handleActiveView() {
    this.dragging = true;
    const dialogs = this.dialog.openDialogs;
    for (let dialog of dialogs) {
      if (dialog.componentInstance instanceof FileViewerDialogComponent) {
        const wrapper = dialog.componentInstance.globalWrapper;
        if (dialog.componentInstance === this) {
          if (!dialog.componentInstance.onTop) {
            // wrapper.style.zIndex = (++FileViewerDialogComponent.zIndex).toString();
            this.onTop = true;
          }
        } else if (dialog.componentInstance.onTop) {
          dialog.componentInstance.onTop = false;
        }
      }
    }
  }

  @HostListener("window:mouseup")
  onWindowMouseUp() {
    this.dragging = false;
  }

  public getGlobalWrapperEl() {
    let outer: HTMLElement = this.viewContainerRef.element.nativeElement;
    while (outer.parentNode) {
      outer = outer.parentNode as HTMLElement;
      if (outer.classList.contains("cdk-global-overlay-wrapper")) {
        return outer;
      }
    }
    return outer;
  }
}
