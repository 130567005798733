import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/core/auth.service';
import { SingleSignOnService } from 'src/app/services/core/single-sign-on.service';
import { LoadingService } from 'src/app/services/core/loading.service';
import { FullFeedbackAlertType } from 'src/app/models/enums/full-feedback-alert-type';
import { MatDialog } from '@angular/material/dialog';
import { FullFeedbackAlertComponent } from '../../shares/full-feedback-alert/full-feedback-alert.component';
import { SnackbarMessageComponent } from '../../shares/snackbar-message/snackbar-message.component';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {
  user: any;

  constructor(private loadingService: LoadingService,
    private singleSignOnService: SingleSignOnService,
    private dialog: MatDialog,
    private authService: AuthService,
    private snackbar: MatSnackBar) {
    this.loadingService.setLoading(true);
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.singleSignOnService.isLoggedIn) {
        let oauth_data: any = {
          oauth_access_token: this.singleSignOnService.getAccessToken()
        };

        this.authService.oauthLogin(oauth_data).subscribe({
          next: (res: any) => {
            if (res && res.status == 1) {
              return res;
            }
            else {
              this.loadingService.setLoading(false);
              let ref_dialog = this.dialog.open(FullFeedbackAlertComponent, {
                width: '500px',
                disableClose: true,
                autoFocus: false,
                data: {
                  title: "",
                  messages: ["form.errors.login.invalid_role"],
                  type: FullFeedbackAlertType.Warning,
                  confirm: false
                }
              });

              ref_dialog.afterClosed().subscribe((result: any) => {
                this.singleSignOnService.onLogout();
              });
            }
          }
        });
      }
    }, 2000);
  }

  openErrorSnackbar(msg: string, params?: object): void {
    this.snackbar.openFromComponent(SnackbarMessageComponent, {
      data: {
        message: msg,
        params
      },
      duration: 1500,
      panelClass: "panelClass-error"
    });
  }

  ngOnDestroy(): void {
    this.loadingService.setLoading(false);
  }
}
