import { Injectable } from '@angular/core';
import { EXTENDED_ROUTE_PMS } from '../models/core/enums/route-permission';
import { MenuItem } from '../models/core/menu-item';
import { SECTION } from '../models/core/enums/permission.enum';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor() {}

  getMenuItems(permissions: string[]): MenuItem[] {
    return [
      {
        name: SECTION.DASHBOARD,
        path: '/dashboard',
        icon: 'dashboard',
        root: 'dashboard',
        // isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) => permissions?.includes(p)).length === 0,
      },
      {
        name: 'menu.enterprise.title',
        icon: 'account_balance',
        root: 'enterprise',
        children: [
          {
            name: 'menu.enterprise.request',
            path: '/enterprise/enterprise-request',
          },
          {
            name: 'menu.enterprise.enterprise_camdx',
            path: 'enterprise/enterprise-camdx',
          },
          {
            name: 'menu.enterprise.list',
            path: 'enterprise/enterprise-list',
          },
          {
            name: 'menu.enterprise.plan',
            path: 'enterprise/plan',
          },
          {
            name: 'menu.enterprise.mission',
            path: 'enterprise/mission',
          },
          {
            name: 'menu.enterprise.mission_result',
            path: 'enterprise/mission-result',
          },
          {
            name: 'menu.enterprise.follow_up',
            path: 'enterprise/follow-up',
          },
        ],
      },
      {
        name: SECTION.NOTIFICATION,
        icon: 'local_atm',
        expanded: false,
        children: [
          {
            name: SECTION.NOTIFICATION_LETTER,
            path: 'notifi-contribution-payment/notification-letter',
            icon: 'notifications_active',

            // isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => permissions?.includes(p)).length === 0,
          },
          {
            name: SECTION.CONTRIBUTEPAYMENT,
            path: 'notifi-contribution-payment/contribution-payment',
            icon: 'payment'
            // isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => permissions?.includes(p)).length === 0,
          },
          {
            name: SECTION.MISSION_CONTRIBUTE,
            path: 'notifi-contribution-payment/mission-contribution-payment',
            icon:'assignment',
            isNoPermission: EXTENDED_ROUTE_PMS.MISSION_CONTRIBUTE.filter((p) => permissions?.includes(p)).length === 0,
          },
          {
            name: SECTION.MISSION_RESULT,
            path: 'notifi-contribution-payment/mission-report',
            icon:'corporate_fare',
            isNoPermission: EXTENDED_ROUTE_PMS.MISSION_RESULT_CONTRIBUTE.filter((p) => permissions?.includes(p)).length === 0,
          },
        ],
      },
      {
        name: SECTION.TRANSITIONAL_PENALTY,
        title: 'menu.transitional_penalties.title',
        icon: 'post_add',
        roles: [],
        // isNoPermission:
        //   [
        //     ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_VICTIM,
        //     ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_MEDICAL,
        //     ...EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_NO_REFUND,
        //   ].filter((p) => this.permissions?.includes(p)).length === 0,
        children: [
          {
            name: SECTION.NON_PAYMENT,
            title: 'menu.transitional_penalties.non_payment',
            icon: 'post_add',
            path: 'transitional-penalties/non-payment',
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_VICTIM.filter((p) =>
            //     this.permissions?.includes(p)
            //   ).length === 0,
            roles: [],
          },
          {
            name: SECTION.PENALTY,
            title: 'menu.transitional_penalties.penalty',
            icon: 'post_add',
            path: 'transitional-penalties/penalty',
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.BENEFIT_NON_OCCUPATION_MEDICAL.filter((p) =>
            //     this.permissions?.includes(p)
            //   ).length === 0,
            roles: [],
          },
          {
            name: SECTION.PENALTY_MISSION,
            title: 'menu.transitional_penalties.penalty_mission',
            path: 'transitional-penalties/transitional-penalty-mission',
            icon: 'fact_check',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PENALTY_MISSION.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            name: SECTION.PENALTY_MISSION_REPORT,
            title: 'menu.transitional_penalties.mission_report',
            path: 'transitional-penalties/mission-report',
            icon: 'report',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PENALTY_MISSION_RESULT.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            name: SECTION.FOLLOW_UP_PENALTY,
            title: 'menu.transitional_penalties.follow_up_penalty',
            path: 'transitional-penalties/follow-up-penalty',
            icon: 'fact_check',
            isNoPermission:
              EXTENDED_ROUTE_PMS.PENALTY_MISSION_FOLLOW_UP.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      // {
      //   title: 'ជូនដំណឹងវិភាគទាន',
      //   routerLink: '/notification',
      //   icon: 'local_atm',
      // },
      {
        name: SECTION.COMPLAINT,
        title: 'menu.complaint.title',
        icon: 'gavel',
        root: 'complaint',
        children: [
          {
            name: SECTION.COMPLAINT_LIST,
            title: 'menu.complaint.complaint_list',
            path: '/complaint/complaint-list',
            isNoPermission:
              EXTENDED_ROUTE_PMS.COMPLAINT_REQ.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            name: SECTION.COMPLAINT_MISSION,
            title: 'menu.complaint.complaint_mission',
            path: '/complaint/complaint-mission',
            isNoPermission:
              EXTENDED_ROUTE_PMS.COMPLAINT_MISSION.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
          {
            name: SECTION.COMPLAINT_MISSION_REPORT,
            title: 'menu.complaint.complaint_mission_report',
            path: '/complaint/complaint-mission-report/list',
            isNoPermission:
              EXTENDED_ROUTE_PMS.COMPLAINT_MISSION_REPORT.filter((p) =>
                permissions?.includes(p)
              ).length === 0,
            roles: [],
          },
        ],
      },
      {
        name: SECTION.WI_INSPECTION_HEAD,
        path: '/wi-inspection',
        icon: 'report',
        roles: [],
        // isNoPermission:
        //   [
        //     ...EXTENDED_ROUTE_PMS.WI_REQUEST,
        //     ...EXTENDED_ROUTE_PMS.WI_VICTIM,
        //     ...EXTENDED_ROUTE_PMS.WI_MISSION,
        //     ...EXTENDED_ROUTE_PMS.WI_MISSION_RESULT,
        //   ].filter((p) => permissions?.includes(p)).length === 0,
        children: [
          {
            name: SECTION.WI_REQUEST,
            path: '/wi-inspection/request',
            icon: 'app_registration',
            roles: [],
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.WI_REQUEST.filter((p) =>
            //     permissions?.includes(p)
            //   ).length === 0,
          },
          {
            name: SECTION.WI_VICTIM,
            path: '/wi-inspection/wi-member-list',
            icon: 'fact_check',
            roles: [],
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.WI_VICTIM.filter((p) =>
            //     permissions?.includes(p)
            //   ).length === 0,
          },
          {
            name: SECTION.WI_MISSION,
            path: '/wi-inspection/mission',
            icon: 'corporate_fare',
            roles: [],
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.WI_MISSION.filter((p) =>
            //     permissions?.includes(p)
            //   ).length === 0,
          },
          {
            name: SECTION.WI_MISSION_RESULT,
            path: '/wi-inspection/mission-result',
            icon: 'corporate_fare',
            roles: [],
            // isNoPermission:
            //   EXTENDED_ROUTE_PMS.WI_MISSION_RESULT.filter((p) =>
            //     permissions?.includes(p)
            //   ).length === 0,
          },
        ],
      },
      {
        name: SECTION.NSSF_USER,
        path: '/user-admin',
        icon: 'manage_accounts',
        root: 'user-admin',
        // isNoPermission: EXTENDED_ROUTE_PMS.NSSF_USER.filter((p) => permissions?.includes(p)).length === 0,
      },
      {
        name: SECTION.SETTING,
        icon: 'settings',
        // isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => permissions?.includes(p)).length === 0,
        root: 'setting',
        expanded: false,
        children: [
          {
            name: SECTION.SETTING_DEPARTMENT,
            path: 'setting/department',
            icon: 'meeting_room',
            // isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => permissions?.includes(p)).length === 0,
          },
          {
            name: SECTION.SETTING_ROLE,
            path: 'setting/role',
            icon: 'work',
            // isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => permissions?.includes(p)).length === 0,
          },
        ],
      },
    ] as MenuItem[];
  }
}
