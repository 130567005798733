import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPaginatorHidePageInfo]'
})
export class PaginatorHidePageInfoDirective implements AfterViewInit {

  constructor(
    private _el: ElementRef<HTMLElement>
  ) {
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this._el.nativeElement.querySelector('.mat-paginator-range-label').remove()
  }

}
