<!-- <mat-dialog-content>
    <iframe class="viewer" [src]="url" frameborder="0"></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close mat-flat-button class="m-button-outlined">
        <mat-icon>logout</mat-icon> {{ 'button.close' | translate }}
    </button>
</mat-dialog-actions> -->



<div
    class="dialog-title d-flex justify-content-between"
    #dragRef="cdkDrag"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="dragPositionHandler"
    (mousedown)="handleActiveView()"
    (mouseup)="dragging = false"
>
    <!-- (cdkDragMoved)="log($event, 'moved')" -->
    <h2 mat-dialog-title class="name mb-0">{{ filename }}</h2>
    <div class="actions">
        <button color="error" mat-icon-button tabindex="0" class="icon mat-button-reset" mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
</div>

<mat-dialog-content class="file-content">
    <!-- <img *ngIf="isImage" [src]="url"> -->
	<iframe class="main" [class.pe-none]="dragging" [src]="url" frameborder="0"></iframe>
</mat-dialog-content>


